
















import { Component, Vue, Prop } from 'vue-property-decorator';
import Hls from 'hls.js';

@Component({
  components: {}
})
export default class VideoShow extends Vue {
  $refs!: {
    videoElement: HTMLVideoElement;
  };

  @Prop({})
  readonly manifest!: string;

  hls: Hls | null = null;
  hlsSupported = true;

  beforeDestroy() {
    if (this.hls) {
      this.hls.destroy();
    }
    this.hls = null;
  }

  mounted() {
    this.loadVideo();
  }

  async loadVideo(): Promise<void> {
    // eslint-disable-next-line no-constant-condition
    if (!Hls.isSupported()) {
      if (
        this.$refs.videoElement.canPlayType('application/vnd.apple.mpegurl')
      ) {
        this.$refs.videoElement.src = this.manifest;
        this.$refs.videoElement.addEventListener('loadedmetadata', () => {
          this.$refs.videoElement.play();
        });
      } else {
        this.hlsSupported = true;
      }
      return;
    }

    if (!this.hls) {
      this.hls = new Hls({
        debug: true
      });
    }

    this.hls.attachMedia(this.$refs.videoElement);
    this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      if (!this.hls) {
        return;
      }
      this.hls.loadSource(this.manifest);
      // this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
      //   console.log(
      //     'manifest loaded, found ' + data.levels.length + ' quality level'
      //   );
      // });
    });
    this.hls.on(Hls.Events.ERROR, (event, data) => {
      if (!this.hls) {
        return;
      }
      console.error('Video HLS error', event, data);
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.warn('fatal network error encountered, try to recover');
            this.hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.warn('fatal media error encountered, try to recover');
            this.hls.recoverMediaError();
            break;
          default:
            // cannot recover
            this.hls.destroy();
            break;
        }
      }
    });
  }
}
