













































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Events } from '@/components/eventbus/events';
import EventBus from '../eventbus/EventBus.vue';
import { UserModule } from '@/store/user';
import { FanfestEventClass } from '@/interfaces';
import {
  LiveEventDataClass,
  LiveEventStreamInterface,
  MuteOrigin
} from '@common/types/LiveEvent';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';
import socket from '@/services/socket';
import { ClientToServerMessageTypes } from '@common/types/SocketProtocol';
import { getShareableChannelLink, getShareableEventLink } from '@/util/share';
import { isWindowEmbedded } from '@/util/embed';
import { navigateTo, share, writeToClipboard } from '@/util/navigator';

@Component({ components: {} })
export default class ButtonsBottomBar extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  @Prop({})
  readonly selfLiveStream!: LiveEventStreamInterface | undefined;

  @Prop({})
  readonly chatDrawer!: boolean;

  @Prop({})
  readonly canProduce!: boolean;

  MuteOrigin = Object.freeze(MuteOrigin);

  dialog = false;

  // Checks if requesting to start presentation
  presentationRequestActive = false;

  get browserSupportsPresentation() {
    return (
      navigator.userAgent.indexOf('Edg') >= 0 ||
      navigator.userAgent.indexOf('Chrome') >= 0
    );
  }

  get isAdmin(): boolean {
    return false;
  }

  get loggedIn(): boolean {
    return UserModule.isLogged;
  }

  get canRequestCamera(): boolean {
    if (!this.selfLiveStream) {
      return false;
    }
    if (
      !this.selfLiveStream.stream.isOnStage &&
      !this.selfLiveStream.stream.isInvited
    ) {
      return true;
    }
    return false;
  }

  get isEmbedded(): boolean {
    return isWindowEmbedded();
  }

  mounted() {
    EventBus.$on(
      Events.PresentationRequestFinishes,
      this.onPresentationRequestFinishes
    );
  }

  beforeDestroy() {
    EventBus.$off(
      Events.PresentationRequestFinishes,
      this.onPresentationRequestFinishes
    );
  }

  onPresentationRequestFinishes() {
    this.presentationRequestActive = false;
  }

  async goToChannelHome(event: Event) {
    if (this.isEmbedded) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      const url = await getShareableChannelLink(this.event.channelPointer);

      await navigateTo(url);
    } else {
      // Do nothing and let the anchor work as usual :)
    }
  }

  login(): void {
    this.$emit(Events.OpenLogin);
  }

  openLogin(): void {
    this.$emit(Events.OpenLogin);
  }

  closeVideo(): void {
    if (!this.selfLiveStream) {
      return;
    }
    this.$emit(Events.CloseCamera);
    Analytics.track(
      TrackEventEnum.StageLeft,
      EventCategory.Engagement,
      this.event.trackData()
    );
  }

  async share(): Promise<void> {
    Analytics.track(
      TrackEventEnum.GenerateShareLink,
      EventCategory.Engagement,
      this.event.trackData()
    );

    const message = this.$t('share.shareText', {
      channelTitle: this.event.channelPointer.title
    }) as string;

    const shareableLink = await getShareableEventLink(this.event);

    if ('share' in navigator) {
      try {
        await share({
          title: 'FanFest',
          text: message,
          url: shareableLink
        });
      } catch (error) {
        // pass
      }
    } else if (navigator.clipboard) {
      try {
        await writeToClipboard(message + ' ' + shareableLink);

        EventBus.$emit(Events.AlertSuccess, this.$tc('share.clipboard'));
      } catch (error) {
        EventBus.$emit(Events.AlertError, this.$tc('share.shareError'));
      }
    } else {
      EventBus.$emit(Events.AlertError, this.$tc('share.shareError'));
    }
  }

  chat(): void {
    this.$emit(Events.OpenChat);
  }

  hand(): void {
    if (!this.loggedIn && !UserModule.isGuestPlus) {
      return this.openLogin();
    }
    if (this.selfLiveStream) {
      // already open
      return;
    }

    this.$emit(Events.ChatRaiseHand, true);
  }

  presentation(): void {
    if (this.canProduce) {
      this.presentationRequestActive = true;
      this.$emit(Events.TogglePresentation, !this.liveEventData.presentation);
    }
  }

  toggleMuteCamera(): void {
    if (!this.selfLiveStream) {
      return;
    }

    socket.emit(
      ClientToServerMessageTypes.SetUserMuteCamera,
      this.event.objectId,
      this.selfLiveStream.user.objectId,
      this.selfLiveStream.stream.muteCamera === MuteOrigin.NONE
    );
  }

  toggleMuteMicrophone(): void {
    if (!this.selfLiveStream) {
      return;
    }
    socket.emit(
      ClientToServerMessageTypes.SetUserMuteMicrophone,
      this.event.objectId,
      this.selfLiveStream.user.objectId,
      this.selfLiveStream.stream.muteMicrophone === MuteOrigin.NONE
    );
  }

  async logout(): Promise<void> {
    EventBus.$emit(Events.LoggedOut);
  }
}
