import { isWindowEmbedded } from './embed';

const genEventObject = (name: string) => ({
  ask: `${name}:ask`,
  res: `${name}:res`
});
// This is a mirror of what's in sdk2.js. If you change it, change it there too.
const fanfestSdkEvents = {
  location: genEventObject('ff-location'),
  share: genEventObject('ff-share'),
  clipboard: genEventObject('ff-clipboard'),
  navigate: genEventObject('ff-navigate')
};
type FanFestSdkEvent = typeof fanfestSdkEvents;

function postEventMessageToSdk(
  event: FanFestSdkEvent[keyof FanFestSdkEvent],
  data: Record<string, unknown>
): Promise<{ ok: boolean } & Record<string, unknown>> {
  window.parent.postMessage({ key: event.ask, data }, '*');

  return new Promise((resolve, reject) => {
    const handler = (e: MessageEvent) => {
      if (e.data.key === event.res) {
        window.removeEventListener('message', handler);

        const data = e.data.data;

        if (data.ok) {
          resolve(data);
        } else {
          console.error('Error in navigator:', data.error);
          reject(data.error);
        }
      }
    };

    window.addEventListener('message', handler);
  });
}

export async function getLocation(): Promise<{
  href: string;
  origin: string;
  pathname: string;
  queryString: string;
}> {
  const isEmbed = isWindowEmbedded();

  if (isEmbed) {
    const data = await postEventMessageToSdk(fanfestSdkEvents.location, {});

    return {
      href: data.href as string,
      origin: data.origin as string,
      pathname: data.pathname as string,
      queryString: data.queryString as string
    };
  } else {
    const currentLocation = window.document.location;
    const qsIndex = currentLocation.href.indexOf('?');

    let queryString = '';
    if (qsIndex > 0) {
      queryString = currentLocation.href.substring(
        currentLocation.href.indexOf('?') + 1
      );
    }

    return {
      href: currentLocation.href,
      origin: currentLocation.origin,
      pathname: currentLocation.pathname,
      queryString: queryString
    };
  }
}

export async function share({
  title = 'FanFest',
  text,
  url
}: {
  title: string;
  text: string;
  url: string;
}): Promise<void> {
  const isEmbed = isWindowEmbedded();

  const shareObject = {
    title,
    text,
    url
  };

  if (isEmbed) {
    await postEventMessageToSdk(fanfestSdkEvents.share, shareObject);
  } else {
    await navigator.share(shareObject);
  }
}

export async function writeToClipboard(text: string) {
  const isEmbed = isWindowEmbedded();

  if (isEmbed) {
    await postEventMessageToSdk(fanfestSdkEvents.clipboard, { text });
  } else {
    await navigator.clipboard.writeText(text);
  }
}

export async function navigateTo(url: string) {
  const isEmbed = isWindowEmbedded();

  if (isEmbed) {
    await postEventMessageToSdk(fanfestSdkEvents.navigate, { url });
  } else {
    window.location.assign(url);
  }
}
