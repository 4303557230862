import type { Roles } from './Roles';
import { SimulcastStatusEnum } from './Simulfest';
import type { EventUser } from './SocketProtocol';

export enum MuteOrigin {
  NONE = 'NONE',
  SELF = 'SELF',
  CHANNEL = 'CHANNEL'
}

export interface LiveEventStreamUserInterface {
  // user id (can be a randomly generated one for GraphQL by parse)
  id: string;
  // user object id (actual db)
  objectId: string;
  // user name
  name: string;
  // user role
  role: Roles;
  // channel object id, if it exists
  worksChannelPointerId: string | null;
}

/**
 * State for each of our live streams.
 */
export interface LiveEventStreamStateInterface {
  // user was invited to stage, waiting for confirmation
  isInvited: boolean;
  // user is VIP
  isVIP: boolean;
  // user is muted
  muteMicrophone: MuteOrigin;
  muteCamera: MuteOrigin;
  // user is on stage
  isOnStage: boolean;
  // user is streaming
  isStreaming: boolean;
  // user is presenting (sharing screen)
  isPresenting: boolean;
  // date in ISO format, last change to a session value.
  // Used to have a stable sorting for the streams on the frontend.
  sessionChanged: string;
}

/**
 * This is the public information for each stream, shared with all users.
 * It includes public user information.
 */
export interface LiveEventStreamInterface {
  user: {
    name: string;
    objectId: string;
  };
  stream: LiveEventStreamStateInterface;
}

export interface LiveEventPresentation {
  user: {
    name: string;
    objectId: string;
  };
}

/**
 * This is the live event data. It holds all public transient data for the event itself.
 * Public and shared with all connected users.
 */
export interface LiveEventDataInterface {
  // has the event started yet?
  started: boolean;
  // was it already transmitted and finished?
  transmitted: boolean;
  // is simulcasting on?
  simulcasting: boolean;
  // current status
  simulcastStatus: SimulcastStatusEnum;
  // what is the url for the embedded content being shown? Might be empty
  embedURL: string;
  // the actual base event url
  eventURL: string;
  // list of running video streams
  streams: LiveEventStreamInterface[];
  // list of blocked user ids
  blockedUserIds: string[];
  // topic
  topic: {
    title: string;
    subtitle: string;
    show: boolean;
  };
  // active presentation
  presentation: LiveEventPresentation | null;
}

/**
 * This is the live event admin data. It holds all private transient data for the event itself.
 * Shared only with producers/admins.
 */
export interface LiveEventAdminDataInterface {
  users: EventUser[];
}

/**
 * The entire live event data as a class.
 */
export class LiveEventDataClass implements LiveEventDataInterface {
  started = false;
  transmitted = false;
  simulcasting = false;
  simulcastStatus = SimulcastStatusEnum.initial;
  embedURL = '';
  eventURL = '';
  streams: LiveEventStreamInterface[] = [];
  blockedUserIds: string[] = [];
  topic = {
    title: '',
    subtitle: '',
    show: false
  };

  presentation: LiveEventPresentation | null = null;

  constructor (data?: LiveEventDataClass | LiveEventDataInterface) {
    if (data) {
      Object.assign(this, data);
    }
  }

  update (data: LiveEventDataClass | LiveEventDataInterface) {
    Object.assign(this, data);
  }
}
