import { render, staticRenderFns } from "./Channel.vue?vue&type=template&id=1f3d6fbb&scoped=true&"
import script from "./Channel.vue?vue&type=script&lang=ts&"
export * from "./Channel.vue?vue&type=script&lang=ts&"
import style0 from "./Channel.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./Channel.vue?vue&type=style&index=1&id=1f3d6fbb&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f3d6fbb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBtn,VContainer,VDialog,VIcon,VMenu,VSkeletonLoader,VSpacer})
