import { render, staticRenderFns } from "./VipStream.vue?vue&type=template&id=3b9d6820&scoped=true&"
import script from "./VipStream.vue?vue&type=script&lang=ts&"
export * from "./VipStream.vue?vue&type=script&lang=ts&"
import style0 from "./VipStream.vue?vue&type=style&index=0&id=3b9d6820&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b9d6820",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
