export interface SimulfestStream {
  status: string;
  name: string;
}

export enum SimulcastStatusEnum {
  initial = 'initial',
  rejection = 'rejection',
  connecting = 'connecting',
  running = 'running',
  runningFfmpeg = 'running ffmpeg',
  ready = 'ready',
  stopping = 'stopping',
  stopped = 'stopped',
  openingBrowser = 'opening browser',
  waitingRtmp = 'waiting rtmp',
  tcpError = 'tcp error',
  teeError = 'tee error',
}

export interface SimulfestStatus {
  version: string;
  status: SimulcastStatusEnum;
  error: string;

  serverUrl: string;
  eventObjectId: string;
  eventUrl: string;
  rtmpUrl: string;

  streams: SimulfestStream[];
  progress: Record<string, string | number | null | undefined>;
}
