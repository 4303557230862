import { render, staticRenderFns } from "./PostEvent.vue?vue&type=template&id=3e2da401&scoped=true&"
import script from "./PostEvent.vue?vue&type=script&lang=ts&"
export * from "./PostEvent.vue?vue&type=script&lang=ts&"
import style0 from "./PostEvent.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./PostEvent.vue?vue&type=style&index=1&id=3e2da401&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e2da401",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
installComponents(component, {VBtn,VDialog,VIcon,VMain,VMenu,VSpacer,VSystemBar})
