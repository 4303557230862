export enum Events {
  Login = 'Login',
  LoggedIn = 'LoggedIn',
  LoggedOut = 'LoggedOut',
  PostLoggedOut = 'PostLoggedOut',

  SocketConnected = 'SocketConnected',
  SocketLogin = 'SocketLogin',

  OpenChat = 'OpenChat',
  OpenLogin = 'OpenLogin',

  CloseCamera = 'CloseCamera',
  CloseDialog = 'CloseDialog',

  AlertErrorPersistent = 'AlertErrorPersistent',
  AlertError = 'AlertError',
  AlertSuccess = 'AlertSuccess',
  AlertInfo = 'AlertInfo',

  ChatSendMessage = 'ChatSendMessage',
  ChatRaiseHand = 'ChatRaiseHand',
  TogglePresentation = 'TogglePresentation',

  PresentationRequestFinishes = 'PresentationRequestFinishes'
}
