var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vip__container"},[_c('content-aspect-ratio',{staticClass:"video__container memberstream__container"},[_c('div',{staticClass:"memberstream__content_stream_background"}),_c('video',{ref:"videoElement",class:{
        memberstream__content_stream: true,
        'memberstream__content_stream-self': _vm.isSelfStream,
        'memberstream__content_stream--stream_loaded': _vm.streamLoaded,
        memberstream__content_landscape: _vm.videoAspectRatio >= 1.0,
        memberstream__content_portrait: _vm.videoAspectRatio < 1.0
      },style:({
        visibility:
          _vm.liveStream.stream.muteCamera === _vm.MuteOrigin.NONE
            ? 'visible'
            : 'hidden'
      }),attrs:{"playsinline":"","autoplay":""},domProps:{"muted":_vm.isMutedMicrophone}}),(_vm.event.channelPointer.image)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.liveStream.stream.muteCamera !== _vm.MuteOrigin.NONE),expression:"liveStream.stream.muteCamera !== MuteOrigin.NONE"}],class:{
        memberstream__content_stream: true,
        'memberstream__content_stream--stream_loaded': _vm.streamLoaded
      },attrs:{"src":_vm.event.channelPointer.image.url}}):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.forceMuted && _vm.streamLoaded && _vm.wantsAudio),expression:"forceMuted && streamLoaded && wantsAudio"}],staticClass:"memberstream__unmute",on:{"click":_vm.manualUnmute}},[_vm._v(" "+_vm._s(_vm.$t('video.clickEnableAudio'))+" ")]),(_vm.showLabel)?_c('div',{staticClass:"memberstream__name"},[_vm._v(_vm._s(_vm.lowerName))]):_vm._e(),(_vm.showControls)?_c('video-button-controls',{attrs:{"event":_vm.event,"live-stream":_vm.liveStream}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }