



















































































































































































import { LogInInput, SignUpInput } from '@/generated/graphql';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Events } from '../eventbus/events';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { UserModule } from '@/store/user';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';
import { UserGraphQLTranslator } from '@/interfaces';
import { Roles } from '@common/types/Roles';
import EventBus from '../eventbus/EventBus.vue';
import { loginSuccess } from './login';
import ISO6391 from 'iso-639-1';

@Component({ components: { ValidationObserver, ValidationProvider } })
export default class LoginForm extends Vue {
  @Prop({
    default: 'Sign in'
  })
  readonly title!: string;

  @Prop({
    default: true
  })
  readonly showClose!: boolean;

  name = '';
  email = '';
  password = '';
  loading = false;
  showPassword = false;
  valid = true;

  // selected tab
  tab = 'login';

  loginErrors = '';
  signupErrors = '';
  // $refs!: {
  //   form: HTMLFormElement;
  // };

  async login(): Promise<void> {
    // this.$refs.form.validate();
    this.loading = true;
    this.loginErrors = '';

    try {
      const input: LogInInput = {
        username: this.email.toLocaleLowerCase(),
        password: this.password
      };
      const result = await this.$apollo.mutate({
        // Query
        mutation: require('./logIn.graphql'),
        // Parameters
        variables: {
          input
        }
      });

      loginSuccess(
        result.data.logIn.viewer.sessionToken,
        result.data.logIn.viewer.user
      );
      EventBus.$emit(Events.LoggedIn);
      Analytics.setUser(UserModule.user);
      Analytics.track(TrackEventEnum.Login, EventCategory.Engagement);
      Analytics.track(TrackEventEnum.FanIdentified, EventCategory.Engagement);
      this.$emit(Events.LoggedIn);
    } catch (error: any) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  close(): void {
    this.$emit(Events.CloseDialog);
  }

  async signup(): Promise<void> {
    this.loading = true;
    this.signupErrors = '';
    const browserLanguage = navigator.language.substring(0, 2);
    const input: SignUpInput = {
      fields: {
        username: this.email.toLocaleLowerCase(),
        password: this.password,
        email: this.email.toLocaleLowerCase(),
        name: this.name,
        role: Roles.Fan,
        language: ISO6391.validate(browserLanguage) ? browserLanguage : 'en',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };
    try {
      const result = await this.$apollo.mutate({
        // Query
        mutation: require('./signUp.graphql'),
        // Parameters
        variables: {
          input
        }
      });
      UserModule.setSessionToken(result.data.signUp.viewer.sessionToken);
      UserModule.setUser(UserGraphQLTranslator(result.data.signUp.viewer.user));
      EventBus.$emit(Events.LoggedIn);
      this.$emit(Events.LoggedIn);
      Analytics.setUser(UserModule.user);
      Analytics.track(
        TrackEventEnum.RegistrationFinished,
        EventCategory.Engagement
      );
      Analytics.track(TrackEventEnum.FanIdentified, EventCategory.Engagement);
    } catch (error: any) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }
}
