import axios from 'axios';

const channelsWithPolls = {
  testchannel: 'fanfestr2',
  premierleague: 'fanfestr2',
  psg: 'psgfanfest'
};

async function initPolls(channelName, userName, userID) {
  if (!window.SportBuff) {
    // console.error('Polls SDK not loaded');
    window.onSportBuffReady = initPolls;
    return;
  }

  if (!userName || !userID) {
    console.error('Polls not supported for anonymous (excl. G+) users');
    return;
  }

  if (!channelsWithPolls[channelName]) {
    console.error('Polls not supported on channel');
    return;
  }

  const container = document.getElementById('contentArea');
  if (!container) {
    console.error('SportBuff container not found');
    return;
  }

  console.log(`Initializing Polls for channel ${channelName} and user ${userName} (${userID})`);

  // get token from backend
  let token;
  const options = {
    method: 'POST',
    url: 'https://staging.fanfest.io/.netlify/functions/sportbuff',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      channelName: channelName,
      userName: userName,
      userID: userID
    }
  };
  try {
    const { data } = await axios.request(options);
    token = data.refreshToken;
    console.log('poll token');
    console.log(data);
  } catch (error) {
    console.error(error);
  }

  let sportBuffContainer;

  const appendContainer = (containerToAppend) => {
    sportBuffContainer = containerToAppend;
    container.appendChild(containerToAppend);
  };
  const destroyContainer = () => {
    if (!sportBuffContainer) return;
    container.removeChild(sportBuffContainer);
  };
  const handleSignUp = () => {
    // Your custom sign up process can start here.
    // Once it finishes, you can call our signUserIn
    // function providing a correct Token
    console.log(`Signing in user to Poll service with token ${token}`);
    window.widget.signUserIn(token);
  };

  // Storing the SportBuff SDK into the window object can be handy for future reference
  window.widget = await window?.SportBuff.init({
    clientName: 'bys',
    player: 'custom-functions',
    streamSourceId: channelsWithPolls[channelName],
    anonymousLogin: true,
    appendContainer: appendContainer,
    destroyContainer: destroyContainer,
    token: token,
    onSignUp: handleSignUp
  });
}

export default initPolls;
