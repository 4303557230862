var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"text-h1 font-weight-light mb-12"},[_vm._v(" "+_vm._s(_vm.fanfestConfig.objectId ? _vm.$t('form.edit') : _vm.$t('form.create'))+" "+_vm._s(_vm.$t('fanfestConfig.objectName'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.name'),"rules":_vm.nameEditable ? '' : 'required|max:200|channelname'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('fanfestConfig.nameLabel'),"required":"","disabled":!!_vm.fanfestConfig.id,"pattern":"[a-z0-9]+"},model:{value:(_vm.fanfestConfig.name),callback:function ($$v) {_vm.$set(_vm.fanfestConfig, "name", $$v)},expression:"fanfestConfig.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.title'),"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('fanfestConfig.titleLabel'),"required":""},model:{value:(_vm.fanfestConfig.title),callback:function ($$v) {_vm.$set(_vm.fanfestConfig, "title", $$v)},expression:"fanfestConfig.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.description'),"rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-textarea',{attrs:{"counter":1000,"error-messages":errors,"label":_vm.$t('fanfestConfig.description')},model:{value:(_vm.fanfestConfig.description),callback:function ($$v) {_vm.$set(_vm.fanfestConfig, "description", $$v)},expression:"fanfestConfig.description"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.channelHeroColor'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"required"},[_c('label',{staticClass:"v-label"},[_vm._v(_vm._s(_vm.$t('fanfestConfig.channelHeroColor')))])]),_c('v-color-picker',{staticClass:"ma-2",attrs:{"mode":"hexa","hide-mode-switch":"","error-messages":errors},model:{value:(_vm.fanfestConfig.channelHeroColor),callback:function ($$v) {_vm.$set(_vm.fanfestConfig, "channelHeroColor", $$v)},expression:"fanfestConfig.channelHeroColor"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.image'),"rules":(_vm.requiredImage + "image")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-file-input',{staticClass:"required",attrs:{"outlined":"","truncate-length":"15","error-messages":errors,"label":_vm.$t('fanfestConfig.image'),"prepend-icon":"camera","accept":"image/png, image/jpeg, image/webp"},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.imagePreview)?_c('v-img',{attrs:{"src":_vm.imagePreview}}):_vm._e()],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.channelBackground'),"rules":(_vm.requiredImage + "image")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-file-input',{staticClass:"required",attrs:{"outlined":"","truncate-length":"15","error-messages":errors,"label":_vm.$t('fanfestConfig.channelBackground'),"prepend-icon":"camera","accept":"image/png, image/jpeg, image/webp"},model:{value:(_vm.channelBackground),callback:function ($$v) {_vm.channelBackground=$$v},expression:"channelBackground"}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.channelBackgroundPreview)?_c('v-img',{attrs:{"src":_vm.channelBackgroundPreview}}):_vm._e()],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.eventBackground'),"rules":"image|eventBackgroundImage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-file-input',{attrs:{"outlined":"","truncate-length":"15","error-messages":errors,"label":_vm.$t('fanfestConfig.eventBackground'),"prepend-icon":"camera","accept":"image/png, image/jpeg, image/webp"},model:{value:(_vm.eventBackground),callback:function ($$v) {_vm.eventBackground=$$v},expression:"eventBackground"}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.eventBackgroundPreview)?_c('v-img',{attrs:{"src":_vm.eventBackgroundPreview}}):_vm._e()],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.sponsorName'),"rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('fanfestConfig.sponsorName')},model:{value:(_vm.fanfestConfig.sponsorName),callback:function ($$v) {_vm.$set(_vm.fanfestConfig, "sponsorName", $$v)},expression:"fanfestConfig.sponsorName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.sponsorImage'),"rules":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-file-input',{attrs:{"outlined":"","truncate-length":"15","error-messages":errors,"label":_vm.$t('fanfestConfig.sponsorImage'),"prepend-icon":"camera","accept":"image/png, image/jpeg, image/webp"},model:{value:(_vm.sponsorImage),callback:function ($$v) {_vm.sponsorImage=$$v},expression:"sponsorImage"}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.sponsorImagePreview)?_c('v-img',{attrs:{"src":_vm.sponsorImagePreview}}):_vm._e(),(_vm.sponsorImagePreview)?_c('v-btn',{on:{"click":_vm.sponsorImageDelete}},[_vm._v(_vm._s(_vm.$t('form.delete')))]):_vm._e()],1)],1)]}}],null,true)}),(_vm.isAdmin)?_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.isActive')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":_vm.$t('fanfestConfig.isActive'),"error-messages":errors},model:{value:(_vm.fanfestConfig.isActive),callback:function ($$v) {_vm.$set(_vm.fanfestConfig, "isActive", $$v)},expression:"fanfestConfig.isActive"}})]}}],null,true)}):_vm._e(),(_vm.isAdmin)?_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.enableGatedEvents')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":_vm.$t('fanfestConfig.enableGatedEvents'),"error-messages":errors},model:{value:(_vm.fanfestConfig.enableGatedEvents),callback:function ($$v) {_vm.$set(_vm.fanfestConfig, "enableGatedEvents", $$v)},expression:"fanfestConfig.enableGatedEvents"}})]}}],null,true)}):_vm._e(),(_vm.isAdmin)?_c('validation-provider',{attrs:{"name":_vm.$t('fanfestConfig.enableShowCreationNotifications')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":_vm.$t('fanfestConfig.enableShowCreationNotifications'),"error-messages":errors},model:{value:(_vm.fanfestConfig.enableShowCreationNotifications),callback:function ($$v) {_vm.$set(_vm.fanfestConfig, "enableShowCreationNotifications", $$v)},expression:"fanfestConfig.enableShowCreationNotifications"}})]}}],null,true)}):_vm._e(),_c('h2',{staticClass:"text-h2"},[_vm._v(_vm._s(_vm.$t('address.address')))]),_c('address-edit',{ref:"addressEdit",model:{value:(_vm.fanfestConfig.addressPointer),callback:function ($$v) {_vm.$set(_vm.fanfestConfig, "addressPointer", $$v)},expression:"fanfestConfig.addressPointer"}}),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2 text-uppercase",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$t('form.cancel'))+" ")]),_c('v-btn',{staticClass:"text-uppercase",attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading || invalid}},[_vm._v(_vm._s(_vm.$t('form.save')))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }