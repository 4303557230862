






























































































import { FanfestEventClass } from '@/interfaces';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LiveEventDataClass } from '@common/types/LiveEvent';
import socket from '@/services/socket';
import { ClientToServerMessageTypes } from '@common/types/SocketProtocol';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';
import RestreamWidget from '../../restream/RestreamWidget.vue';
import TopicControl from './TopicControl.vue';
import { SimulcastStatusEnum } from '@common/types/Simulfest';
/* eslint-disable graphql/template-strings */

enum SimulcastStatusUI {
  LOADING,
  CAN_START,
  WAITING_START,
  RUNNING,
  ERROR
}

@Component({ components: { RestreamWidget, TopicControl } })
export default class TransmissionTab extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  // target RTMP
  streamUrl = 'rtmp://live.restream.io/live';
  streamKey = '';
  manuallyStopped = false;
  SimulcastStatusUI = Object.freeze(SimulcastStatusUI);
  simulcastStatus = SimulcastStatusUI.LOADING;

  /**
   * Tells RestreamWidget if we configured the stream in this session -
   * to set default meta titles for social media.
   */
  configuredInCurrentSession = false;

  @Watch('liveEventData.simulcastStatus', { immediate: true })
  onSimulcastStatusChange(value: SimulcastStatusEnum): void {
    this.updateSimulcastStatus(value);
  }

  mounted() {
    this.updateSimulcastStatus(this.liveEventData.simulcastStatus);
  }

  updateSimulcastStatus(status: SimulcastStatusEnum): void {
    switch (status) {
      case SimulcastStatusEnum.waitingRtmp:
        if (this.simulcastStatus === SimulcastStatusUI.LOADING) {
          this.simulcastStatus = SimulcastStatusUI.CAN_START;
        }
        break;
      case SimulcastStatusEnum.running:
      case SimulcastStatusEnum.ready:
        this.simulcastStatus = SimulcastStatusUI.RUNNING;
        break;
      // TODO: error codes?
    }
  }

  get showRestreamWidget(): boolean {
    return this.liveEventData.started && !this.event.transmitted;
  }

  onStreamKey(streamKey: string): void {
    this.streamKey = streamKey;
  }

  startSimulcast(): void {
    this.simulcastStatus = SimulcastStatusUI.WAITING_START;
    this.ingestRTMPSimulfest();
  }

  ingestRTMPSimulfest(): void {
    const rtmpServerUrl =
      this.streamUrl +
      (this.streamUrl.endsWith('/') ? '' : '/') +
      this.streamKey;

    socket.emit(
      ClientToServerMessageTypes.SimulcastStartRTMP,
      this.event.objectId,
      rtmpServerUrl
    );
  }

  goLive(): void {
    this.configuredInCurrentSession = true;

    const eventUrl = window.location.origin + this.event.url;
    socket.emit(
      ClientToServerMessageTypes.StartEvent,
      this.event.objectId,
      eventUrl
    );
    Analytics.track(
      TrackEventEnum.EventStarted,
      EventCategory.Engagement,
      this.event.trackData()
    );
  }

  stopTransmission(): void {
    if (!confirm(this.$tc('producerControls.confirmStop'))) {
      return;
    }
    this.manuallyStopped = true;
    socket.emit(ClientToServerMessageTypes.StopEvent, this.event.objectId);
    Analytics.track(
      TrackEventEnum.EventEnded,
      EventCategory.Engagement,
      this.event.trackData()
    );
  }
}
