







































































































































































import { Component, Watch } from 'vue-property-decorator';
import BottomBar from '@/components/event/BottomBar.vue';
import ContentArea from '@/components/event/ContentArea.vue';
import ContentAspectRatio from '@/components/event/ContentAspectRatio.vue';
import ProducerControls from '@/components/event/producer/ProducerControls.vue';
import VideoButtonControls from '@/components/event/VideoButtonControls.vue';
import JoinStreamDialog from '@/components/event/JoinStreamDialog.vue';
import Chat from '@/components/chat/Chat.vue';
import { MetaInfo } from 'vue-meta';
import LoginForm from '@/components/auth/LoginForm.vue';
import { UserModule } from '@/store/user';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import {
  ClientToServerMessageTypes,
  ServerToClientMessageTypes
} from '@common/types/SocketProtocol';
import socket from '@/services/socket';
import { Socket } from 'vue-socket.io-extended';
import { LiveEventStreamInterface, MuteOrigin } from '@common/types/LiveEvent';
import { phenixData, PhenixMember } from '../util/phenix';
import { bottomBarHeight } from '@/components/event/layout';
import EventMock from '@/components/debug/EventMock.vue';
import BaseEvent from './BaseEvent';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';
import { Route } from 'vue-router';
import type { PhenixStreamMap } from '@/util/phenix';
import { isMobile } from '@/util/mobile';
import PostEvent from './PostEvent.vue';
import { isWindowEmbedded } from '@/util/embed';
import Logo from '@/components/util/Logo.vue';
import { navigateTo } from '@/util/navigator';
import { getShareableChannelLink } from '@/util/share';

@Component({
  components: {
    Logo,
    BottomBar,
    Chat,
    ContentAspectRatio,
    ContentArea,
    LoginForm,
    ProducerControls,
    VideoButtonControls,
    JoinStreamDialog,
    EventMock,
    PostEvent
  }
})
export default class Event extends BaseEvent {
  /*
   * dialogs/sidebars
   */
  // is the chat drawer open?
  chatDrawer = false;

  // is the login dialog open?
  loginDialog = false;

  // is the bottom bar visible?
  bottomBar = true;

  // has the user been invited to stage?
  hasActiveInvite = false;

  // is the join stream dialog open?
  joinStreamDialog = false;

  // have we interacted with the page yet?
  notInteracted = true;

  // if we're a producer, check the browser resolution etc
  isMobileBrowser = false;

  // if we're a producer, check if we want to produce
  producerFeatures = true;

  // if we connect to the same event twice, first connection
  // will be marked as duplicate and disconnected.
  hadDuplicatedConnection = false;

  // post login action callback
  postLoginAction: (() => void) | null = null;

  // are we in a debug environment?
  isDebug =
    process.env.NODE_ENV === 'development' ||
    window.location.hostname.includes('staging');

  get containerClasses(): (string | boolean)[] {
    return [
      'event__container',
      this.canProduce && 'event__container--producer',
      this.isMobileBrowser
        ? 'event__container--mobile-layout'
        : 'event__container--desktop-layout'
    ];
  }

  metaInfo(): MetaInfo {
    const title = this.event.title + ' FanFest';
    const description =
      this.event.description || 'Come watch a FanFest of your favorite team!';
    return {
      title: title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description
        },
        {
          vmid: 'twitter:title',
          property: 'twitter:title',
          content: title
        },
        {
          vmid: 'twitter:description',
          property: 'twitter:description',
          content: description
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: description
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content:
            this.event.eventBackground?.url ||
            this.event.channelPointer.image?.url ||
            'https://live.fanfest.io/logo/logo_square_fanfest_1024.png'
        }
      ]
    };
  }

  get canJoinEvent() {
    if (
      !this.event.transmitted &&
      this.event.channelPointer.enableGatedEvents &&
      this.isEmbedded &&
      UserModule.isGuestPlus &&
      !UserModule.user.username
    ) {
      return false;
    }

    return true;
  }

  get gatingRedirectUrl() {
    const url = new URLSearchParams(window.location.href);
    const gatingRedirectUrl = url.get('fanfest_gating_redirect');

    return gatingRedirectUrl ?? '';
  }

  async goToChannelHome(event: any) {
    if (this.isEmbedded) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      const url = await getShareableChannelLink(this.event.channelPointer);

      await navigateTo(url);
    } else {
      // Do nothing and let the anchor work as usual :)
    }
  }

  async goToGatingFallback() {
    if (this.gatingRedirectUrl) {
      await navigateTo(this.gatingRedirectUrl);
    }
  }

  async created() {
    this.isMobileBrowser = isMobile(window.navigator);

    this.fetchEvent(this.$route.params?.id).then(async () => {
      if (this.event.transmitted) return;

      if (!this.canJoinEvent) return;

      await this.joinRoomSocket();
      try {
        await this.phenixInit();
      } catch (err) {
        console.log(err);
      }
      await this.joinRoomPhenix(this.phenixRoomJoined, this.membersChanged);
    });
    this.fetchOffers(this.$route.params?.channel);
  }

  async mounted(): Promise<void> {
    document.documentElement.classList.add('full-page-height');

    if (this.$vuetify.breakpoint.mdAndUp && !this.isEmbedded) {
      this.chatDrawer = true;
    }

    EventBus.$on(Events.SocketLogin, this.onSocketLogin);
    EventBus.$on(Events.PostLoggedOut, this.onPostLoggedOut);

    setTimeout(() => {
      this.initSprig();
    }, 2000);
  }

  beforeRouteLeave(_to: Route, _from: Route, next: any): void {
    if (this.liveEventData.started) {
      const answer = window.confirm(this.$tc('producerControls.confirmQuit'));
      if (!answer) {
        next(false);
        return;
      }
    }
    next();
  }

  beforeDestroy(): void {
    EventBus.$off(Events.SocketLogin, this.onSocketLogin);
    EventBus.$off(Events.PostLoggedOut, this.onPostLoggedOut);

    this.leaveRoom();
    document.documentElement.classList.remove('full-page-height');
    this.cleanupPhenix();

    window.Sprig('removeAttributes', ['eventId', 'eventName']);
  }

  async onSocketLogin(): Promise<void> {
    await this.joinRoomSocket();
  }

  @Socket(ServerToClientMessageTypes.DuplicatedConnection)
  onDuplicatedConnection(): void {
    console.log('Exiting phenix room due to duplicated connections');
    this.cleanupPhenix();
    this.hadDuplicatedConnection = true;
  }

  cleanupPhenix(): void {
    phenixData.roomExpress?.dispose();
    phenixData.roomExpress = null;
    phenixData.presentationRoomExpress?.dispose();
    phenixData.presentationRoomExpress = null;
  }

  join() {
    this.notInteracted = false;

    Analytics.track(TrackEventEnum.ShowViewed, EventCategory.Engagement, this.event.trackData());
  }

  joinAsFan() {
    this.join();
    this.producerFeatures = false;
  }

  joinAsProducer() {
    this.join();
    this.producerFeatures = true;
  }

  onPostLoggedOut(): void {
    this.cameraStop('socket');
    this.joinRoomSocket();
  }

  leaveRoom(): void {
    if (!this.event.objectId) {
      return;
    }
    socket.emit(ClientToServerMessageTypes.LeaveRoom, this.event.objectId);
    Analytics.track(
      TrackEventEnum.EventLeft,
      EventCategory.Engagement,
      this.event.trackData()
    );
  }

  get heightBar(): number {
    if (!this.bottomBar) {
      return 0;
    }
    return bottomBarHeight(
      this.$vuetify.breakpoint.name,
      this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.height
    );
  }

  get selfLiveStream(): LiveEventStreamInterface | undefined {
    return this.liveEventData.streams.find(
      (stream: LiveEventStreamInterface) =>
        stream.user.objectId === UserModule.user.objectId
    );
  }

  @Watch('liveEventData.presentation')
  onPresentationChanged(): void {
    if (!this.liveEventData.presentation && this.presentationStream) {
      this.stopPresenting();
    }
  }

  @Watch('selfLiveStream.stream.muteCamera')
  onMuteCameraChanged(): void {
    // on camera muted, disabled track
    const value = this.selfLiveStream?.stream.muteCamera === MuteOrigin.NONE;
    phenixData.selfSrcObject
      ?.getVideoTracks()
      .forEach((track) => (track.enabled = value));
  }

  @Watch('selfLiveStream.stream.muteMicrophone')
  onMuteMicrophoneChanged(): void {
    // on mic muted, disabled track
    const value =
      this.selfLiveStream?.stream.muteMicrophone === MuteOrigin.NONE;
    phenixData.selfSrcObject
      ?.getAudioTracks()
      .forEach((track) => (track.enabled = value));
  }

  get canProduce(): boolean {
    return this.event.canProduce(UserModule.user);
  }

  get isEmbedded(): boolean {
    return isWindowEmbedded();
  }

  onToggleChat(): void {
    this.chatDrawer = !this.chatDrawer;
  }

  onLoggedIn(): void {
    if (this.postLoginAction) {
      this.postLoginAction();
      this.postLoginAction = null;
    }
    this.loginDialog = false;
  }

  onOpenLogin(postLoginAction: (() => void) | null = null): void {
    this.loginDialog = true;
    this.postLoginAction = postLoginAction;
  }

  onCloseChat(): void {
    this.chatDrawer = false;
  }

  initSprig(): void {
    // if (!this.canProduce) {
      window.Sprig.setAttribute('Channel', this.event.channelPointer.name);
      // window.Sprig.setAttribute('Show', this.event.title);
      window.Sprig('track', 'Entered-FanFest-Code-Trigger-v2');
    }
  // }

  async onAcceptInviteDialog(): Promise<void> {
    try {
      this.hasActiveInvite = false;
      await this.onChatRaiseHand();
    } catch (e) {
      console.error(e);
    }
  }

  async onDeclineInviteDialog(): Promise<void> {
    this.hasActiveInvite = false;

    socket.emit(
      ClientToServerMessageTypes.SetUserStage,
      this.event.objectId,
      UserModule.user.objectId,
      false
    );
  }

  async onChatRaiseHand(): Promise<void> {
    this.joinStreamDialog = true;
  }

  async onJoinStreamCancels(): Promise<void> {
    this.joinStreamDialog = false;

    if (this.hasActiveInvite) {
      this.onDeclineInviteDialog();
    }
  }

  async onJoinStreamSucceeds(devices: {
    audioInputDeviceId: string;
    videoInputDeviceId: string;
  }): Promise<void> {
    try {
      await this.cameraStart(devices);

      Analytics.track(
        TrackEventEnum.RaisedHand,
        EventCategory.Engagement,
        this.event.trackData()
      );

      // If producer get right to stage
      if (this.canProduce) {
        socket.emit(
          ClientToServerMessageTypes.SetUserStage,
          this.event.objectId,
          UserModule.user.objectId,
          true
        );
      } else {
        EventBus.$emit(
          Events.AlertInfo,
          this.$tc('fanfestEvent.handRaisedAlert')
        );
      }
    } catch (e) {
      // pass, we handled it before
      console.error(e);
    }
  }

  async onTogglePresentation(isPresenting: boolean) {
    try {
      if (isPresenting) {
        await this.presentationStart();
      } else {
        this.stopPresenting();
      }

      Analytics.track(
        isPresenting
          ? TrackEventEnum.StartPresentation
          : TrackEventEnum.StopPresentation,
        EventCategory.Engagement,
        this.event.trackData()
      );

      if (this.canProduce) {
        socket.emit(
          isPresenting
            ? ClientToServerMessageTypes.StartPresentation
            : ClientToServerMessageTypes.StopPresentation,
          this.event.objectId,
          UserModule.user.objectId
        );
      }
    } catch (e) {
      // pass, we handled it before
      console.error(e);
      return;
    } finally {
      EventBus.$emit(Events.PresentationRequestFinishes);
    }
  }

  async joinRoomSocket(): Promise<void> {
    try {
      await this._joinRoomSocket();
    } catch (e) {
      this.chatDrawer = false;
    }
  }

  @Socket()
  connect() {
    this.joinRoomSocket();
  }

  @Socket(ServerToClientMessageTypes.InviteUserToStage)
  onInviteUserToStage(objectId: string): void {
    if (this.event.objectId !== objectId) {
      return;
    }
    this.hasActiveInvite = true;
    this.onChatRaiseHand();
  }

  onCloseSelfVideo(): void {
    this.cameraStop('user');
  }

  phenixRoomJoined(error: any, response: any): void {
    if (error) {
      EventBus.$emit(
        Events.AlertError,
        'Unable to join room: ' + error.message
      );
      this.cameraStop('socket');

      throw error;
    }

    if (response.status === 'room-not-found') {
      // Handle room not found - Create a Room Or Publish to a Room
    } else if (response.status !== 'ok' && response.status !== 'ended') {
      EventBus.$emit(Events.AlertError, 'New Status: ' + response.status);
      throw new Error(response.status);
    } else if (response.status === 'ok' && response.roomService) {
      phenixData.roomService = response.roomService;
      this.phenixInitChatService();
    }
  }

  // subscriberCallback
  membersChanged(members: PhenixMember[]): void {
    this._membersChanged(members);
    this._propagateCamera();
  }

  _propagateCamera(): void {
    const selfStreams: PhenixStreamMap[] = [];

    this.phenixStreamMap.forEach((phenixStream) => {
      // if it's us, tell everyone we started our camera. this avoids a race condition.
      if (
        phenixStream.objectId === UserModule.user.objectId &&
        this.isCameraPropagated === false
      ) {
        selfStreams.push(phenixStream);
        socket.emit(
          ClientToServerMessageTypes.StartUserCamera,
          this.event.objectId
        );
        this.isCameraPropagated = true;
      }
    });

    if (selfStreams.length > 1) {
      console.error('multiple self streams', selfStreams);
    }
  }
}
