




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import TransmissionTab from './TransmissionTab.vue';
import UsersTab from './UsersTab.vue';
import ContentTab from './ContentTab.vue';
import { FanfestEventClass } from '@/interfaces';
import {
  LiveEventAdminDataInterface,
  LiveEventDataClass
} from '@common/types/LiveEvent';
import { isWindowEmbedded } from '@/util/embed';

@Component({ components: { TransmissionTab, UsersTab, ContentTab } })
export default class ProducerControls extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  @Prop({})
  readonly liveEventAdminData!: LiveEventAdminDataInterface;

  // selected tab
  tab = 2;

  unusableScreenDialog = false;
  unusableScreenDialogMessage = '';

  producerControlsHidden = true;

  get selfLocation(): string {
    return window.location.toString();
  }

  get isEmbedded(): boolean {
    return isWindowEmbedded();
  }

  get containerClassName() {
    return {
      producer_controls__container: true,
      'producer_controls__container--hidden': this.producerControlsHidden
    };
  }

  get toggleClassName() {
    return { 'producer_controls__toggle--button': this.producerControlsHidden };
  }

  toggle() {
    this.producerControlsHidden = !this.producerControlsHidden;
  }

  openParent() {
    parent.postMessage({ navigate: this.selfLocation }, '*');
  }
}
